import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "mpgshcLoaderWrap" }
const _hoisted_2 = { class: "" }
const _hoisted_3 = ["textContent"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, { class: "payPage" }, {
    default: _withCtx(() => [
      _createVNode(_component_ion_content, { class: "payPage" }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("p", {
                textContent: _toDisplayString(_ctx.t('checkout.paymentProcessing'))
              }, null, 8, _hoisted_3)
            ])
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}