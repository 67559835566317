
import { defineComponent } from "vue";
import { IonPage, IonContent, onIonViewWillEnter } from "@ionic/vue";
import settingsApp from "@/libs/settings";
import httpApp from "@/libs/http";
import { useI18n } from "vue-i18n";
import { useGlobalFunctions } from "@/libs/globalFunctions";
import { useRouter } from "vue-router";
import { useStore } from "@/store";

export default defineComponent({
	name: "PayProcessing",
	props: ["payment_method", "order_no"],
	setup(props) {
		const { t, te } = useI18n({ useScope: "global" });
		const router = useRouter();
		const storeX = useStore();
		const { createLoading, openToast } = useGlobalFunctions();

		const mpgsCheckoutConfiguration = async () => {
			const domainLangUrl =
				window.location.origin + "/" + storeX.state.storeCode;
			const sentData = {
				order_no: props.order_no,
				return_callback_url:
					domainLangUrl +
					"/pay/confirming/return/" +
					props.order_no +
					"/egyprotech_mpgshc/",
				error_callback_url:
					domainLangUrl +
					"/pay/confirming/error/" +
					props.order_no +
					"/egyprotech_mpgshc/",
				cancel_callback_url:
					domainLangUrl +
					"/pay/confirming/cancel/" +
					props.order_no +
					"/egyprotech_mpgshc/",
			};
			const loading = await createLoading(t("pleaseWait"));
			loading.present();
			httpApp
				.sendPostRequest(
					settingsApp.getEndpointUrl("mpgsConfiguration"),
					sentData
				)
				.then((resp: any) => {
					if (resp.success) {
						const configurePayment =
							"Checkout.configure(" +
							JSON.stringify(resp.configurations) +
							");\n";

						const callbaclkScript = document.createElement("script");
						callbaclkScript.appendChild(
							document.createTextNode(configurePayment)
						);

						const startPayment =
							resp.payment_type == "lightbox"
								? "Checkout.showLightbox();"
								: "Checkout.showPaymentPage();";
						const startPaymentScript = document.createElement("script");
						callbaclkScript.appendChild(document.createTextNode(startPayment));
						const integrationPlugin = document.createElement("script");
						integrationPlugin.setAttribute("src", resp.integration_js_src);
						document.body.appendChild(integrationPlugin);
						setTimeout(() => {
							loading.dismiss();
							document.body.appendChild(callbaclkScript);
							document.body.appendChild(startPaymentScript);
						}, 2000);
					} else {
						loading.dismiss();
						if (te("apiMsgs." + resp.error.code)) {
							openToast(t("apiMsgs." + resp.error.code), "danger");
						} else {
							openToast(resp.error.message, "danger");
						}
						router.replace("/cart");
					}
				})
				.catch((error) => {
					loading.dismiss();
					console.log(error, "error");
					router.replace("/cart");
				});
		};

		const stryveCreatePayment = async () => {
			const domainLangUrl =
				window.location.origin + "/" + storeX.state.storeCode;
			const returnUrl =
				domainLangUrl +
				"/pay/confirming/return/" +
				props.order_no +
				"/egyprotech_stryve/";

			const sentData = {
				order_no: props.order_no,
				return_url: returnUrl,
			};
			const loading = await createLoading(t("pleaseWait"));
			loading.present();
			httpApp
				.sendPostRequest(
					settingsApp.getEndpointUrl("stryveCreatePayment"),
					sentData
				)
				.then((resp: any) => {
					if (resp.success) {
						if (Object.prototype.hasOwnProperty.call(resp, "redirect_url")) {
							window.location.href = resp.redirect_url;
						} else {
							loading.dismiss();
							if (!Object.prototype.hasOwnProperty.call(resp, "paid")) {
								if (resp.paid == "1") {
									openToast("checkout.paymentSuccessMsg", "success");
									router.replace("/checkout/success/" + props.order_no);
								} else {
									router.replace("/cart");
								}
							}
						}
					} else {
						loading.dismiss();
						if (te("apiMsgs." + resp.error.code)) {
							openToast(t("apiMsgs." + resp.error.code), "danger");
						} else {
							openToast(resp.error.message, "danger");
						}
						router.replace("/cart");
					}
				})
				.catch((error) => {
					loading.dismiss();
					console.log(error, "error");
					router.replace("/cart");
				});
		};

		const paytabsCreatePayment = async () => {
			const domainLangUrl =
				window.location.origin + "/" + storeX.state.storeCode;
			const returnUrl =
				domainLangUrl +
				"/pay/confirming/return/" +
				props.order_no +
				"/egyprotech_paytabs/";

			const sentData = {
				order_no: props.order_no,
				return_url: returnUrl,
			};
			const loading = await createLoading(t("pleaseWait"));
			loading.present();
			httpApp
				.sendPostRequest(
					settingsApp.getEndpointUrl("paytabsCreatePayment"),
					sentData
				)
				.then((resp: any) => {
					if (resp.success) {
						if (Object.prototype.hasOwnProperty.call(resp, "redirect_url")) {
							window.location.href = resp.redirect_url;
						} else {
							loading.dismiss();
							if (!Object.prototype.hasOwnProperty.call(resp, "paid")) {
								if (resp.paid == "1") {
									openToast("checkout.paymentSuccessMsg", "success");
									router.replace("/checkout/success/" + props.order_no);
								} else {
									router.replace("/cart");
								}
							}
						}
					} else {
						loading.dismiss();
						if (te("apiMsgs." + resp.error.code)) {
							openToast(t("apiMsgs." + resp.error.code), "danger");
						} else {
							openToast(resp.error.message, "danger");
						}
						router.replace("/cart");
					}
				})
				.catch((error) => {
					loading.dismiss();
					console.log(error, "error");
					router.replace("/cart");
				});
		};

		const fawryCreatePayment = async () => {
			const domainLangUrl =
				window.location.origin + "/" + storeX.state.storeCode;
			const returnUrl =
				domainLangUrl +
				"/pay/confirming/return/" +
				props.order_no +
				"/egyprotech_fawry/";

			const sentData = {
				order_no: props.order_no,
				return_url: returnUrl,
			};
			const loading = await createLoading(t("pleaseWait"));
			loading.present();
			httpApp
				.sendPostRequest(
					settingsApp.getEndpointUrl("fawryCreatePayment"),
					sentData
				)
				.then((resp: any) => {
					console.log(resp);
					if (resp.success) {
						if (Object.prototype.hasOwnProperty.call(resp, "redirect_url")) {
							window.location.href = resp.redirect_url;
						} else {
							loading.dismiss();
							if (!Object.prototype.hasOwnProperty.call(resp, "paid")) {
								if (resp.paid == "1") {
									openToast("checkout.paymentSuccessMsg", "success");
									router.replace("/checkout/success/" + props.order_no);
								} else {
									router.replace("/cart");
								}
							}
						}
					} else {
						loading.dismiss();
						if (te("apiMsgs." + resp.error.code)) {
							openToast(t("apiMsgs." + resp.error.code), "danger");
						} else {
							openToast(resp.error.message, "danger");
						}
						router.replace("/cart");
					}
				})
				.catch((error) => {
					loading.dismiss();
					console.log(error, "error");
					router.replace("/cart");
				});
		};

		onIonViewWillEnter(() => {
			if (props.payment_method == "egyprotech_mpgshc") {
				mpgsCheckoutConfiguration();
			} else if (props.payment_method == "egyprotech_stryve") {
				stryveCreatePayment();
			} else if (props.payment_method == "egyprotech_paytabs") {
				paytabsCreatePayment();
			} else if (props.payment_method == "egyprotech_fawry") {
				fawryCreatePayment();
			}
		});

		return {
			t,
		};
	},
	components: {
		IonPage,
		IonContent,
	},
});
